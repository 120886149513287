import React, { useCallback, useMemo } from "react";
import Autocomplete, { OptionShape } from "@civicplus/preamble-ui/lib/Autocomplete";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { formatPhoneValue, phoneOptions } from "./PhoneNumberHelper";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { PhoneNumber, PhoneNumberType } from "./PhoneNumberUtils";
import { WeatherChannel } from "../../../types/Subscription";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2, 0)
    },
    card: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`
    },
    flexGrow: {
        flexGrow: 1
    }
}));

export type PhoneNumberListProps = {
    existingPhoneNumbers?: PhoneNumber[];
    selectedPhoneNumbers?: PhoneNumber[];
    onChangeSelectedPhoneNumber: (type: PhoneNumberType, selectedPhoneNumber?: PhoneNumber) => void;
    weatherChannels?: WeatherChannel[];
};

export const PhoneNumberList: React.FC<PhoneNumberListProps> = (props) => {
    const { existingPhoneNumbers, onChangeSelectedPhoneNumber, selectedPhoneNumbers } = props;
    const classes = useStyles();

    const handleProvideChange = (type: PhoneNumberType) => (phoneNumber: OptionShape | undefined) => {
        const konexusPhoneNumber = existingPhoneNumbers?.find((p) => p.id === phoneNumber?.value);
        if (onChangeSelectedPhoneNumber) {
            onChangeSelectedPhoneNumber(type, konexusPhoneNumber);
        }
    };

    const availablePhoneNumbers = useMemo(() => {
        if (existingPhoneNumbers && existingPhoneNumbers.length > 0) {
            return existingPhoneNumbers?.filter(
                (existing) => !selectedPhoneNumbers?.some((selected) => selected.id === existing.id)
            );
        }

        return [];
    }, [existingPhoneNumbers, selectedPhoneNumbers]);

    const selectNumber = useCallback(
        (type: PhoneNumberType) => {
            return selectedPhoneNumbers?.find((phone) => phone.type === type);
        },
        [selectedPhoneNumbers]
    );

    const selectedCellPhone = useMemo(() => {
        return formatPhoneValue(selectNumber(PhoneNumberType.CellPhone));
    }, [selectNumber]);

    const selectedCellPhoneSecondary = useMemo(() => {
        return formatPhoneValue(selectNumber(PhoneNumberType.CellPhoneSecondary));
    }, [selectNumber]);

    const selectedHomePhone = useMemo(() => {
        return formatPhoneValue(selectNumber(PhoneNumberType.HomePhone));
    }, [selectNumber]);

    const selectedWorkPhone = useMemo(() => {
        return formatPhoneValue(selectNumber(PhoneNumberType.WorkPhone));
    }, [selectNumber]);

    return (
        <>
            {existingPhoneNumbers && existingPhoneNumbers?.length > 0 ? (
                <div className={classes.root}>
                    <Typography variant="h6">Mobile and Voice Alerts</Typography>

                    <Autocomplete
                        id="cellPhone"
                        label="Cell Phone"
                        helperText="This number requires support of SMS/Text messages - This number is required to receive weather alerts"
                        placeholder="Please select a phone number from the dropdown"
                        onChange={handleProvideChange(PhoneNumberType.CellPhone)}
                        isClearable={true}
                        isInDialog={true}
                        options={phoneOptions(availablePhoneNumbers, true)}
                        value={selectedCellPhone}
                    />

                    <Autocomplete
                        id="cellPhoneSecondary"
                        label="Cell Phone 2/Pager"
                        helperText="This number will only receive SMS/Text messages"
                        placeholder="Please select a phone number from the dropdown"
                        onChange={handleProvideChange(PhoneNumberType.CellPhoneSecondary)}
                        isClearable={true}
                        isInDialog={true}
                        options={phoneOptions(availablePhoneNumbers, true)}
                        value={selectedCellPhoneSecondary}
                    />

                    <Typography variant="h6">Voice Alerts</Typography>

                    <Autocomplete
                        id="homePhone"
                        label="Home Phone"
                        helperText="This number is required to receive weather alerts"
                        placeholder="Please select a phone number from the dropdown"
                        onChange={handleProvideChange(PhoneNumberType.HomePhone)}
                        isClearable={true}
                        isInDialog={true}
                        options={phoneOptions(availablePhoneNumbers)}
                        value={selectedHomePhone}
                    />

                    <Autocomplete
                        id="workPhone"
                        label="Work Phone"
                        placeholder="Please select a phone number from the dropdown"
                        onChange={handleProvideChange(PhoneNumberType.WorkPhone)}
                        isClearable={true}
                        isInDialog={true}
                        options={phoneOptions(availablePhoneNumbers)}
                        value={selectedWorkPhone}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default PhoneNumberList;
