import React, { useCallback, useEffect, useState } from "react";
import AlertsSubscriptionDialog, { DataType, IKonexusLanguages } from "../AlertsSubscriptionDialog";
import Icon from "@mdi/react";
import Toggle from "@civicplus/preamble-ui/lib/Toggle";
import Tooltip from "@civicplus/preamble-ui/lib/Tooltip";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { mdiBellRing } from "@mdi/js";
import { useAuth } from "../../providers/AuthProvider";
import { SubscriptionList, SubscriptionType, WeatherChannel } from "../../types/Subscription";
import { Organization } from "../../types/Organization";
import { ProductType } from "../../types/ProductType";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { NotificationsService } from "../../services/notificationsService";
import { SimpleDialog } from "../SimpleDialog/SimpleDialog";
import { GAevent } from "../../services/googleAnalyticsService";
import { isStaffUser } from "../../shared/functions";
import { setupAcrValue } from "../react-oidc-context";
import { SubscriptionProfileInformation, UserAccountDetails } from "../../types/Account";
import { useApplicationPhoneNumbers } from "../../hooks/useApplicationPhoneNumbers";
import { useAccountInfo } from "../../hooks/useAccountInfo";
import { SigninPopupArgs } from "oidc-client-ts";
import { DocumentType } from "../../types/SearchDocument";
import { setProfileInfo, validateKonexusInfo } from "../../shared/alertFunctions";
import { useSnackbar } from "notistack";

interface AlertToggleProps {
    /**
     * The id of the toggle
     */
    id: string;
    /**
     * Whether or not the user is subscribed to the list
     */
    subscribed: boolean;
    /**
     * The svg path icon to display next to the toggle
     */
    iconPath?: string | string[];
    /**
     * The text to display in the tooltip for the icon
     */
    tooltipText?: string;
    /**
     * The text to display next to the icon
     */
    label?: string;
    /**
     * The subscription list
     */
    subscription: SubscriptionList;
    /**
     * The current organization
     */
    organization: Organization;
    /**
     * Configured notifications service;
     */
    notificationService: NotificationsService;
    /**
     * All the subscriptions list
     */
    alerts?: SubscriptionList[];
    /**
     * Callback function to be called when the user starts changing the subscription
     */
    onChangeStart?: () => void;
    /**
     * Callback function to be called when the user finishes changing the subscription
     */
    onChangeComplete?: (success: boolean, subscribedToDefaultGroups: boolean) => void;
    /**
     * The user's language options for the Preferred Language step in the subscription dialog
     */
    languages: IKonexusLanguages;
    /**
     * The user's account details from Account Service
     */
    userAccountDetails?: UserAccountDetails;
    /**
     * Function to be called when an account reload is needed
     */
    reloadAccountDetails?: () => void;
    /**
     * The document type for the subscription used to determine whether the subscription is for alerts or weather
     */
    documentType?: DocumentType;
    /**
     * The weather channels which the user will be subscribed to
     */
    weatherChannels?: WeatherChannel[];

    handlePendingSubscription: boolean;
}

interface PendingChanges {
    [key: string]: boolean;
}

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    label: {
        marginLeft: theme.spacing(1)
    },
    iconGroup: {
        display: "flex"
    }
}));

const buildLocalStorageKey = (aggregateId: string, weatherChannels?: string[]) => {
    return `retrySubscriptionAction_alert_${aggregateId}${weatherChannels === undefined ? "" : "_" + weatherChannels.join("_")}`;
};

export const AlertToggle: React.FC<AlertToggleProps> = (props) => {
    const {
        id,
        subscription,
        organization,
        notificationService,
        onChangeStart,
        onChangeComplete,
        subscribed,
        languages,
        userAccountDetails,
        reloadAccountDetails,
        documentType,
        weatherChannels,
        iconPath = mdiBellRing,
        tooltipText = "Receive Alert Notifications",
        label,
        handlePendingSubscription
    } = props;

    const auth = useAuth();
    const authUser = auth.user;
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);
    const [loginNoticeOpen, setLoginNoticeOpen] = useState(false);
    const [pendingChanges, setPendingChanges] = useState<PendingChanges>({});
    const [, phoneNumbers] = useApplicationPhoneNumbers();
    const [isAccountInfoLoading, , reloadAccountInfo] = useAccountInfo();

    const convertTypeToMessage = (listName: string, unSubscribed: boolean) => {
        return `You are now ${unSubscribed ? "unsubscribed" : "subscribed"} for alerts from ${listName}`;
    };

    const handleUserUnsubscribe = useCallback(
        async (subscription: SubscriptionList) => {
            let response = undefined;
            let success = false;

            setPendingChanges((prevState) => {
                const existingPending = { ...prevState };

                existingPending[subscription.aggregateId] = true;

                return { ...existingPending };
            });

            const phoneNumber =
                userAccountDetails?.phoneNumbers != null && userAccountDetails.phoneNumbers.length > 0
                    ? userAccountDetails?.phoneNumbers[0].number
                    : "";

            response = await notificationService.unsubscribeUserFromList({
                aggregateId: subscription.aggregateId,
                subscriptionType: SubscriptionType.EmailAndSms,
                organization,
                authUser,
                subscriptionName: subscription.name,
                phoneNumber: phoneNumber,
                documentType: documentType,
                weatherChannels: weatherChannels,
                profileInfo: null
            });

            if (!response.error) {
                const message = convertTypeToMessage(subscription.name, true);

                enqueueSnackbar(message, {
                    variant: "success"
                });

                if (!isStaffUser(authUser)) {
                    GAevent({
                        action: `User Unsubscribed - [Alert]`,
                        category: "Subscriptions",
                        orgName: organization.name,
                        label: subscription.name
                    });
                }

                success = subscription.productType !== ProductType.CivicEngageCentral;
            } else {
                const errorMessage = response.error ? response.error : `Error while trying to unsubscribe user to list`;

                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    persist: true
                });
            }

            setPendingChanges((prevState) => {
                const existingPending = { ...prevState };
                existingPending[subscription.aggregateId] = false;

                return { ...existingPending };
            });

            onChangeComplete && onChangeComplete(success, false);

            return success;
        },
        [
            authUser,
            documentType,
            notificationService,
            onChangeComplete,
            organization,
            userAccountDetails?.phoneNumbers,
            weatherChannels,
            enqueueSnackbar
        ]
    );

    const handleLogin = () => {
        const args = {
            state: { from: window.location.pathname }
        } as SigninPopupArgs;

        auth.signinPopup(setupAcrValue(auth, args, organization?.id));
        setLoginNoticeOpen(false);
    };

    const handleClose = () => {
        setIsOpen(false);
        localStorage?.removeItem(buildLocalStorageKey(subscription.aggregateId, weatherChannels));
    };

    const handleNewUserSubscription = useCallback(
        async (subscription: SubscriptionList, signupData: DataType | null) => {
            let response = undefined;
            let success = false;

            onChangeStart && onChangeStart();

            setPendingChanges((prevState) => {
                const existingPending = { ...prevState };

                existingPending[subscription.aggregateId] = true;

                return { ...existingPending };
            });

            const subscribeToDefault =
                signupData == null || signupData.subscribeToDefault === undefined
                    ? false
                    : signupData.subscribeToDefault;

            const profileInfo: SubscriptionProfileInformation | null = setProfileInfo(signupData);

            response = await notificationService.subscribeUserToList({
                aggregateId: subscription.aggregateId,
                subscriptionType: SubscriptionType.EmailAndSms,
                organization,
                authUser,
                subscriptionName: subscription.name,
                phoneNumber: "",
                subscribeToDefault,
                documentType: documentType,
                weatherChannels: weatherChannels,
                profileInfo
            });

            if (!response.error) {
                const message = convertTypeToMessage(subscription.name, false);

                enqueueSnackbar(message, {
                    variant: "success"
                });

                if (!isStaffUser(authUser)) {
                    GAevent({
                        action: `User Subscribed - [Alert]`,
                        category: "Subscriptions",
                        orgName: organization.name,
                        label: subscription.name
                    });
                }

                success = subscription.productType !== ProductType.CivicEngageCentral;
            } else {
                const errorMessage = response.error ? response.error : `Error while trying to  subscribe user to list`;

                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    persist: true
                });
            }

            setPendingChanges((prevState) => {
                const existingPending = { ...prevState };
                existingPending[subscription.aggregateId] = false;

                return { ...existingPending };
            });

            onChangeComplete && onChangeComplete(success, subscribeToDefault);

            if (!response.error) {
                handleClose();
            }

            return success;
        },
        [
            authUser,
            documentType,
            organization,
            weatherChannels,
            notificationService,
            onChangeComplete,
            onChangeStart,
            enqueueSnackbar
        ]
    );

    const handleUserSubscribe = useCallback(() => {
        const { isValid } = validateKonexusInfo(userAccountDetails, phoneNumbers, subscription.applicationId);

        if (!isValid) {
            setIsOpen(true);
            return;
        }

        handleNewUserSubscription(subscription, null);
    }, [phoneNumbers, subscription, userAccountDetails, handleNewUserSubscription]);

    const handleOpen = useCallback(
        (subscription: SubscriptionList, checked: boolean | undefined = undefined) => {
            if (checked) {
                if (authUser) {
                    handleUserSubscribe();
                } else {
                    setLoginNoticeOpen(true);

                    const subscriptionCall = JSON.stringify({
                        checked,
                        subscription
                    });

                    localStorage?.setItem(
                        buildLocalStorageKey(subscription.aggregateId, weatherChannels),
                        subscriptionCall
                    );
                }
            } else {
                handleUserUnsubscribe(subscription);
            }
        },
        [authUser, weatherChannels, handleUserSubscribe, setLoginNoticeOpen, handleUserUnsubscribe]
    );

    useEffect(
        function handleRetrySubscription() {
            const retryAlertSubscriptionAction = handlePendingSubscription
                ? localStorage?.getItem(buildLocalStorageKey(subscription.aggregateId, weatherChannels))
                : null;

            if (authUser && retryAlertSubscriptionAction) {
                const retryObj = JSON.parse(retryAlertSubscriptionAction);
                const { subscription, checked } = retryObj;

                handleOpen(subscription, checked);
            }
        },
        [authUser, weatherChannels, handleOpen, subscription.aggregateId]
    );

    return (
        <>
            <div className={classes.root}>
                <Tooltip delay="instant" placement="bottom" title={tooltipText}>
                    {iconPath instanceof Array ? (
                        <span className={classes.iconGroup}>
                            {iconPath.map((path, i) => (
                                <Icon key={i} path={path} size={1} />
                            ))}
                        </span>
                    ) : (
                        <Icon path={iconPath} size={1} color={undefined} />
                    )}
                </Tooltip>

                {label && (
                    <Typography variant="body1" className={classes.label}>
                        {label}
                    </Typography>
                )}

                <Toggle
                    id={`Subscribe to Alerts - ${id}`}
                    checked={subscribed}
                    data-testid={id}
                    loading={pendingChanges[subscription.aggregateId] || Boolean(isAccountInfoLoading && authUser)}
                    onChange={async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        handleOpen(subscription, checked);
                    }}
                    inputProps={{
                        "aria-label": `${tooltipText} for ${subscription.name}`
                    }}
                />
            </div>

            <SimpleDialog
                isOpen={loginNoticeOpen}
                title="Sign in needed"
                primaryBtnText="Sign In or Create an Account"
                primaryAction={handleLogin}
                onClose={() => {
                    setLoginNoticeOpen(false);
                    localStorage?.removeItem(buildLocalStorageKey(subscription.aggregateId, weatherChannels));
                }}
            >
                <Typography>You must be signed in to subscribe.</Typography>
            </SimpleDialog>

            {isOpen && (
                <AlertsSubscriptionDialog
                    open={isOpen}
                    onClose={handleClose}
                    languages={languages}
                    userAccountDetails={userAccountDetails}
                    reloadAccountDetails={reloadAccountDetails}
                    organization={organization}
                    applicationId={subscription.applicationId}
                    documentType={documentType}
                    phoneNumbers={phoneNumbers[subscription.applicationId]}
                    showDefaultSubscriptionCheck={documentType === DocumentType.AlertList}
                    onSubmit={async (signUpData: DataType) => {
                        const success = await handleNewUserSubscription(subscription, signUpData);
                        reloadAccountInfo();
                        return success;
                    }}
                />
            )}
        </>
    );
};

export default AlertToggle;
